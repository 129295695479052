// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const adminPermissionsSlice = createSlice({
  name: "adminPermissions",
  initialState: {
    permissions: []
  },
  reducers: {
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    }
  }
});

export const { setPermissions } = adminPermissionsSlice.actions;
export default adminPermissionsSlice.reducer;
