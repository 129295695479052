import { createContext } from "react";
import { Ability } from "@casl/ability";
import { createContextualCan } from "@casl/react";
import { useSelector } from "react-redux";

export const initialAbility = new Ability();

export const AbilityContext = createContext(initialAbility);

export const Can = createContextualCan(AbilityContext.Consumer);

const AbilityProvider = ({ children }) => {
  const adminPermissions = useSelector(
    (state) => state?.adminPermissionsSlice?.permissions
  );

  const ability = new Ability(
    adminPermissions ? adminPermissions : { action: "read", subject: "AUTH" }
  );

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};

export default AbilityProvider;
